import { Container, Skeleton } from "@mui/material";

import { memo } from "react";
import { trpcReact } from "../../trpc";

export const HomePage = memo(function HomePage() {
  const getMeQuery = trpcReact.auth.getMe.useQuery();

  return (
    <Container maxWidth="sm">
      {getMeQuery.isLoading && <Skeleton />}
      {getMeQuery.data && <>Bonjour {getMeQuery.data.publicId}</>}
    </Container>
  );
});
