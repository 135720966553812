import {
  Link as MaterialLink,
  Button as MaterialButton,
  type ButtonProps,
} from "@mui/material";
import { Link as RouterLink } from "@tanstack/react-router";
import { memo, type ComponentProps, type ReactNode } from "react";

type RouterLinkProps = ComponentProps<typeof RouterLink>;

export const Link = memo<{ children: ReactNode } & RouterLinkProps>(
  function Link(props) {
    return (
      <MaterialLink component={RouterLink} {...props}>
        {props.children}
      </MaterialLink>
    );
  }
);

export const ButtonLink = memo<ButtonProps & RouterLinkProps>(
  function ButtonLink(props) {
    return (
      <MaterialButton component={RouterLink} {...props}>
        {props.children}
      </MaterialButton>
    );
  }
);
