import { RootRoute, Route, Router, Outlet } from "@tanstack/react-router";

import { LoginPage } from "./pages/login";
import { NotFoundPage } from "./pages/not-found";
import { HomePage } from "./pages/home";
import { Container, CssBaseline } from "@mui/material";
import { MainLayout } from "./layouts/main";
import { LogoutPage } from "./pages/logout";

const rootRoute = new RootRoute({
  component: () => (
    <>
      <CssBaseline />
      <MainLayout>
        <Outlet />
      </MainLayout>
    </>
  ),
});

const notFoundRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "*",
  component: NotFoundPage,
});

const loginRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/login",
  component: LoginPage,
});

const logoutRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/logout",
  component: LogoutPage,
});

const homeRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/",
  component: HomePage,
});

const routeTree = rootRoute.addChildren([
  notFoundRoute,
  loginRoute,
  logoutRoute,
  homeRoute,
]);

export const router = new Router({ routeTree });
