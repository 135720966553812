import { Alert } from "@mui/material";
import { memo } from "react";
import { Link } from "../../components/links";

export const NotFoundPage = memo(function NotFoundPage() {
  return (
    <Alert severity="warning">
      Cette page n'existe pas.{" "}
      <Link to="/" search={{}} params={{}}>
        Retourner à l'accueil
      </Link>
    </Alert>
  );
});
