import { memo, useEffect } from "react";
import { Alert } from "@mui/material";
import { useNavigate } from "@tanstack/react-router";

import { trpcReact } from "../../trpc";

export const LogoutPage = memo(function LogoutPage() {
  const logoutMutation = trpcReact.auth.logout.useMutation();

  const navigate = useNavigate();

  useEffect(() => {
    if (logoutMutation.isSuccess) {
      navigate({ to: "/" });
    }
  }, [navigate, logoutMutation.isSuccess]);

  useEffect(() => {
    logoutMutation.mutate();
  }, []);

  return (
    <>
      {logoutMutation.isLoading && (
        <Alert severity="info">Déconnexion en cours...</Alert>
      )}

      {logoutMutation.isError && (
        <Alert severity="error">
          Une erreur est survenue pendant la déconnexion. Veuillez réessayer.
        </Alert>
      )}
    </>
  );
});
