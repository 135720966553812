import {
  AppBar,
  Container,
  Skeleton,
  Toolbar,
  Typography,
} from "@mui/material";
import { memo } from "react";
import { css } from "@emotion/react";
import { trpcReact } from "../trpc";
import { ButtonLink } from "../components/links";

type MainLayoutProps = {
  children: React.ReactNode;
};

export const MainLayout = memo<MainLayoutProps>(function MainLayout({
  children,
}) {
  const getMeQuery = trpcReact.auth.getMe.useQuery(undefined);

  return (
    <Container maxWidth="md">
      <AppBar position="sticky">
        <Toolbar>
          <Typography
            variant="h6"
            component="div"
            css={css`
              flex-grow: 1;
            `}
          >
            ID Protect
          </Typography>

          {getMeQuery.isLoading && (
            <Skeleton variant="rounded" width={136} height={36} />
          )}
          {getMeQuery.data && (
            <ButtonLink
              to="/logout"
              search={{}}
              params={{}}
              variant="contained"
              color="error"
            >
              Déconnexion
            </ButtonLink>
          )}
        </Toolbar>
      </AppBar>

      <div
        css={css`
          padding-top: 32px;
        `}
      >
        {children}
      </div>
    </Container>
  );
});
