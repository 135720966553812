import { memo, useCallback, useEffect, useRef, useState } from "react";
import { Alert, Avatar, Paper, TextField, Typography } from "@mui/material";
import { css } from "@emotion/react";
import { LoadingButton } from "@mui/lab";
import { z } from "zod";
import { useNavigate } from "@tanstack/react-router";

import { trpcReact } from "../../trpc";
import { useForm } from "../../hooks/use-form";

import logoPng from "../../images/logo.png";

export const LoginPage = memo(function LoginPage() {
  const loginMutation = trpcReact.auth.login.useMutation();
  const authenticateMutation = trpcReact.auth.authenticate.useMutation();

  const navigate = useNavigate();

  const loginForm = useForm(LOGIN_SCHEMA);
  const authenticateForm = useForm(AUTHENTICATE_SCHEMA);

  const emailRef = useRef("");

  const onLoginSubmit = useCallback(
    loginForm.handleSubmit((data) => {
      emailRef.current = data.email;
      loginMutation.mutate({ email: data.email });
    }),
    [loginForm, loginMutation.mutate]
  );

  const onAuthenticateSubmit = useCallback(
    authenticateForm.handleSubmit((data) => {
      authenticateMutation.mutate({ email: emailRef.current, code: data.code });
    }),
    [authenticateForm.handleSubmit, authenticateMutation.mutate]
  );

  useEffect(() => {
    if (authenticateMutation.data?.type === "ok") {
      navigate({ to: "/" });
    }
  }, [navigate, authenticateMutation.data]);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
      `}
    >
      <img width="64" src={logoPng} alt="Logo ID Protect" />

      <Typography
        component="h1"
        variant="h5"
        css={css`
          margin-top: 16px;
        `}
      >
        Connexion
      </Typography>

      <form
        css={css`
          width: 100%;
        `}
        onSubmit={
          !loginMutation.isSuccess ? onLoginSubmit : onAuthenticateSubmit
        }
      >
        {!loginMutation.isSuccess && (
          <TextField
            required
            margin="normal"
            fullWidth
            label="Adresse e-mail"
            autoComplete="email"
            autoFocus
            error={!!loginForm.formState.errors.email}
            helperText={loginForm.formState.errors.email?.message}
            {...loginForm.register("email")}
          />
        )}

        {loginMutation.isSuccess && (
          <>
            <Alert severity="info">
              Un e-mail vient de vous être envoyé avec un code unique de
              connexion.
            </Alert>

            {authenticateMutation.data?.type === "err" && (
              <>
                <Alert severity="error">
                  Le code entré est invalide ou a expiré. Veuillez réessayer.
                </Alert>
              </>
            )}

            <TextField
              required
              margin="normal"
              fullWidth
              label="Code"
              autoFocus
              error={!!authenticateForm.formState.errors.code}
              helperText={authenticateForm.formState.errors.code?.message}
              {...authenticateForm.register("code")}
            />
          </>
        )}

        <LoadingButton
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          loading={loginMutation.isLoading || authenticateMutation.isLoading}
        >
          Continuer
        </LoadingButton>
      </form>
    </div>
  );
});

const LOGIN_SCHEMA = z.object({
  email: z.string().email("Adresse e-mail invalide"),
});

const CODE_LENGTH = 8;
const CODE_REGEX = new RegExp(`\\d{${CODE_LENGTH}}`);

const AUTHENTICATE_SCHEMA = z.object({
  code: z
    .string()
    .regex(CODE_REGEX, "Code invalide (il devrait faire 8 chiffres)"),
});
