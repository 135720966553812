import { memo, StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { RouterProvider } from "@tanstack/react-router";
import { ThemeProvider } from "@mui/material";
import { QueryClientProvider } from "@tanstack/react-query";

import { queryClient, trpcReact, trpcReactClient } from "./trpc";
import { router } from "./router";
import { theme } from "./theme";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

const App = memo(function App() {
  return (
    <ThemeProvider theme={theme}>
      <trpcReact.Provider client={trpcReactClient} queryClient={queryClient}>
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router} />
        </QueryClientProvider>
      </trpcReact.Provider>
    </ThemeProvider>
  );
});

const container = document.getElementById("react-root");
if (!container) {
  throw new Error('Element with id "react-root" not found');
}
const root = createRoot(container);
root.render(
  <StrictMode>
    <App />
  </StrictMode>
);
