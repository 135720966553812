import {
  TRPCClientError,
  createTRPCProxyClient,
  createTRPCReact,
  httpBatchLink,
} from "@trpc/react-query";

import type { ApiRouter } from "@idprotect/server";
import { MutationCache, QueryCache, QueryClient } from "@tanstack/react-query";
import { router } from "./router";

const trpcLink = httpBatchLink({
  url: "/proxy/trpc",
  fetch(url, options) {
    return fetch(url, {
      ...options,
      credentials: "include",
    });
  },
});

export const trpcReact = createTRPCReact<ApiRouter>();
export const trpcReactClient = trpcReact.createClient({
  links: [trpcLink],
});

export const client = createTRPCProxyClient<ApiRouter>({
  links: [trpcLink],
});

const isUnauthorizedError = (err: unknown) => {
  return err instanceof TRPCClientError && err.data.code === "UNAUTHORIZED";
};

const handleApiError = (err: unknown) => {
  if (isUnauthorizedError(err)) {
    router.navigate({ to: "/login" });
  }
};

const shouldRetry = (failureCount: number, err: unknown) => {
  if (isUnauthorizedError(err)) return false;

  return failureCount < 3;
};

const CACHE_OPTIONS = { onError: handleApiError };
const RETRY_OPTIONS = { retry: shouldRetry };

export const queryClient = new QueryClient({
  queryCache: new QueryCache(CACHE_OPTIONS),
  mutationCache: new MutationCache(CACHE_OPTIONS),
  defaultOptions: {
    queries: RETRY_OPTIONS,
    mutations: RETRY_OPTIONS,
  },
});
